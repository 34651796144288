.container {
  background-color: #fffaed !important;
}
.titleStyles {
  display: flex;
  justify-content: flex-end;
  .iconStyles {
    cursor: pointer;
  }
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.textboxContainer {
  padding: 10px 0px;
}
.nodeConnectionContainer {
  display: flex;
  flex-direction: row;
}
.nodeConnectionItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextStyles {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
}
.conditionMargin {
  margin: 10px 0px !important;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.optionInnerContainer {
  border: 1px solid black;
  display: flex;
  width: 45%;
  margin: 5px 5px;
}
.optionTextStyles {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}
.iconSubContainer {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addNewNodeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.addParentContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px 0px;
}
.editIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.deleteIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.margin {
  margin: 10px 0px;
}
.minWidth {
  min-width: 400;
}
.actionbutton {
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}
.main{
  margin-top:20px
}
.selectItem{
  border: 1px solid;
    padding: 10px 9px;
    margin:6px 0;
    background: #fffaed;
}