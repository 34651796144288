.heading {
  padding-top: 8%;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  justify-content: center;
}

.paragraph {
  background: transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  justify-content: center;
}

.mains {
  background-color: #fffaed;
  height: 100%;
}
.signinForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fffaed;
}

.signinInput {
  outline: none;
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 25px;
  margin-top: 10px;
  background: transparent;
}

.label {
  opacity: 0.8;
  width: 85%;
}

.btnAuth {
  background-color: black;
  color: white;
  border-radius: 5px;
  margin-bottom: 5%;
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

// .btn.btn-auth:hover {
//   color: white;
// }

.container.authContainer {
  width: 40%;
}

.links {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

// .links:hover {
//   text-decoration: none;
//   color: inherit;
// }

@media screen and (max-width: 700px) {
  .container.auth-container {
    width: 80%;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
