.container {
  background-color: #fffaed !important;
  padding: 10px 10px;
}
.titleStyles {
  display: flex;
  justify-content: flex-end;
  .iconStyles {
    cursor: pointer;
  }
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.textboxContainer {
  padding: 10px 0px;
}
.nodeConnectionContainer {
  display: flex;
  flex-direction: row;
}
.nodeConnectionItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextStyles {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
}
.closeIconContainer {
  position: absolute;
  right: 60px;
  top: 20px;
}
