.container {
  background-color: #fffaed;
}
.titleStyles {
  display: flex;
  justify-content: flex-end;
  .iconStyles {
    cursor: pointer;
  }
}
.toolBarStyles {
  background-color: #fffaed;
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.optionsContainer {
  padding: 20px 0px;
}
.buttonStyles {
  min-width: 100px;
  text-align: center;
  background-color: black;
  color: white;
}
.nextContainer {
  margin: 10px 0px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.nextStyles,.activeButton {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
  margin-right: 10px !important;
}

.deleteIconStyles {
  vertical-align: bottom;
  cursor: pointer;
}
.minWidth {
  min-width: 400;
}
.customMargin {
  margin: 10px 0px;
}
.default{
  box-shadow: none !important;
    border-radius: 0 !important;
    border: 1px solid black !important;
    background:#ebe0bf0d !important;
    color: black !important;
}
.activeButton{
  border-radius: 0 !important;
}