.labelStyles {
  padding: 10px 0px;
  cursor: pointer;
}
.labelStyles:hover {
  background-color: #d3d3d3;
}
.container {
  border: 1px solid #b7b3a7;
  padding: 10px 0px;
  display: flex;
  flex: 1;
  cursor: pointer;
}
.selectElement {
  display: flex;
  flex: 2;
  justify-content: flex-start;
  align-items: center;
}
.arrowDropDownContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.textFieldContainer {
  z-index: 555;
  background-color: #fffaed;
  height: 200px;
  padding: 0px 20px;
  overflow: scroll;
  // border: 1px solid #a8a496;
  margin: 10px 0px;
}
.searchIconStyle {
  position: absolute;
}
.labelStyles {
  padding: 8px 10px;
  margin: 0px;
}
.horizontalLineContainer {
  margin: 0px 15px;
  border-top: 1px solid #a8a496;
}
