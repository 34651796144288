.nextStyles {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
}
.divMargin {
  margin: 10px 0px;
}
.buttonContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin: 10px 0px;
}
.minWidth {
  min-width: 400;
}
.addIfContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0px;
}
.iconContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.iconStyles {
  cursor: pointer;
}
