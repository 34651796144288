.nickName{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.w_89{
  width: 89%;
}
.w_100{
  width: 100%;
}

.nickButton{
  border: 1px solid #b7b3a7 !important;
    display: flex !important;
    height: 56px !important;
    margin-top: 8px !important;
    margin-left: 10px !important;
    border-radius: 0 !important;
}
.borderNone{
  border-radius: 0 !important;
  // display: none;
}
.conditionMargin {
  margin: 10px 0px !important;
}
.nextStyles,.defaultOr {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
  margin: 10px 0px !important;
}
.orContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.ifContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.checkOptionRow {
  display: flex;
  flex-direction: row;
}
.checkboxContainer {
  flex: 0.7;
}
.removeIconContainer {
  display: flex;
  flex: 0.3;
  justify-content: flex-end;
  align-items: center;
}
.removeIconStyles {
  cursor: pointer;
}
.defaultOr{
  background: #ebe0bf12 !important;
    box-shadow: none !important;
    color: black !important;
    border: 1px solid black !important;
}
