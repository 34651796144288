.container {
  height: 150vh !important;
  overflow: hidden;
  background-color: #ebe0bf;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.addNodeContainer {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 10px;
  z-index: 10;
}
.buttonStyles {
  padding: 10px 10px;
  cursor: pointer;
  border-bottom: 1px solid #dcd2b3;
  text-align: center;
  background-color: #ebe0bf;
}
.anchorStyles {
  text-decoration: none;
  color: black;
}
.customNodeStyles {
  background: #c3ccf4;
  color: #fff;
  padding: 5px;
  max-width: 150px;
  max-height: 100px;
  overflow: scroll;
}
.handleStyles {
  border-radius: 0;
  background-color: white;
  z-index: 0;
}
.labelStyles {
  color: black;
  text-align: left !important;
}
.paragraphStyles {
  color: black;
}
.backgroundColor {
  background-color: #fffaed;
}
.imageStyles {
  height: 50px;
  width: 50px;
}
.rotate {
  transform: rotate(180deg);
}
.paragraphWithoutMargin {
  margin: 0;
  padding: 0;
}
.downloadContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addNodeAnimation:hover {
  // box-shadow: 1px 1px 1px 1px #9e9e9e;
  // -moz-box-shadow: 3px 3px 5px 6px #ccc;
  // -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  // box-shadow: 3px 3px 5px 6px #ccc;
  border: 1px solid blue;
}
.deleteIconStyles {
  fill: black !important;
}
.editIconStyles {
  fill: black !important;
}
.deleteIconStyles:hover {
  fill: red !important;
}
.editIconStyles:hover {
  fill: #0e49b5 !important;
}
.options {
  background-color: #ebe0bf;
  width: 200px;
  height: 50px;
  position: absolute;
  top: 80px;
  left: -10px;
  z-index: 22;
  display: none;
}
.fileNone{
  display: none;
}
.customNodeStyles:hover .options {
  display: block;
}
.editDeleteContainer {
  display: flex;
  flex: 1;
}
.deleteIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.editIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.editContainer {
  display: flex;
  flex: 0.05;
}
.emptyDiv {
  flex: 1;
  margin-top: 10px;
  border-right: 1px solid black;
  margin-bottom: 15px;
}
