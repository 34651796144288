.container {
  background-color: #fffaed;
  padding: 60px 60px;
}
.titleStyles {
  display: flex;
  justify-content: flex-end;
  .iconStyles {
    cursor: pointer;
  }
}
.toolBarStyles {
  background-color: #fffaed;
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.optionsContainer {
  padding: 20px 0px;
}
.buttonStyles {
  min-width: 100px;
  text-align: center;
  background-color: black;
  color: white;
}
.nextContainer {
  margin: 10px 25px;
}
.nextStyles {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
}
.deleteIconStyles {
  vertical-align: bottom;
  cursor: pointer;
}
.minWidth {
  min-width: 400;
}
.richTextEditor {
  background-color: #fffaed;
}
.defaultMarginStyles {
  margin: 10px 0px;
}
.mr{
  margin-right: 10px !important;
}