.container {
  background-color: #fffaed !important;
}
.titleStyles {
  display: flex;
  justify-content: flex-end;
  .iconStyles {
    cursor: pointer;
  }
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.textboxContainer {
  padding: 10px 0px;
}
.nodeConnectionContainer {
  display: flex;
  flex-direction: row;
}
.nodeConnectionItem {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nextStyles {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.optionInnerContainer {
  display: flex;
  width: 50%;
}
.optionInnerStyles {
  display: flex;
  flex: 1;
  border: 1px solid black;
  margin-top: 10px;
  margin-left: 10px;
  padding-left: 5px;
}
.optionTextStyles {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}
.iconSubContainer {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addNewNodeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px;
}
.addParentContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 10px 0px;
}
.defaultMarginContainer {
  margin: 10px 0px;
}
.editIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.deleteIconContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.searchOptionContainer {
  background-color: white;
  z-index: 2313;
  width: 320px;
  max-height: 100px;
  box-shadow: 0 0 3px #ccc;
  overflow-y: scroll;
}
.searchOption:hover {
  background-color: #d3d3d3;
}
.searchOptionsPadding {
  padding: 10px 5px;
  cursor: pointer;
}
.minWidth {
  min-width: 400;
}
.childNodeStyles {
  border: 1px solid #b7b3a7;
  padding: 0px 5px;
  border-radius: 5px;
}
.marginLeft{
  margin-left: 10px;
}
.marginRight{
  margin-right: 10px;
}
.marginTop{
  margin-top: 10px;
}
.marginTop20{
  margin-top: 20px;
}
.marginBottom20{
  margin-bottom: 20px;
}
.dflex{
  display: flex;
}

