* {
  font-family: 'Neue Haas Grotesk Text Pro' !important;
  /* background-color: red; */
  /* background: #ebe0bf !important; */
}

/* .css-sghohy-MuiButtonBase-root-MuiButton-root{
  border-radius: 0 !important;
} */
.App {
  /* position: relative; */
  min-height: 100vh;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-flow__controls {
  position: absolute !important;
  left: 80% !important;
  right: 10 !important;
  background: #ebe0bf !important;
}

.rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #fffaed !important;
  text-transform: capitalize;
}

.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
  background: #fffaed !important;
}

div.viewConditions_optionContainer__0pIUC>div {
  width: 100%;
  display: contents;
}

div.linkNode_optionContainer__n\+zoy>div {
  width: 100%;
  display: contents;
}

div.linkNode_optionInnerStyles__hxosb {
  background-color: #FFFAED;
}