.nickName{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.contColr{
  background:#fffaed;
}
.w_89{
  width: 89%;
}
.w_100{
  width: 100%;
}
.nickButton{
  border: 1px solid #b7b3a7 !important;
    display: flex !important;
    height: 56px !important;
    margin-top: 8px !important;
    margin-left: 10px !important;
    border-radius: 0 !important;
}
.closeIconContainer {
  margin: 10px 0px;
}
.deleteIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.deleteStyles {
  cursor: pointer;
}
.conditionMargin {
  margin: 10px 0px !important;
}
.optionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.optionInnerContainer {
  border: 1px solid black;
  display: flex;
  width: 45%;
  margin: 5px 5px;
  background:#fffaed;
}
.optionTextStyles {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}
.iconSubContainer {
  display: flex;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.addNewNodeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.nextStylesContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.nextStyles,.defaultOr {
  min-width: 130px !important;
  text-align: center;
  background-color: black !important;
  color: white;
  margin: 10px 0px !important;
}
.labelStyles {
  color: black;
  font-weight: 800 !important;
}
.margin {
  margin: 10px 0px;
}
.conditionContainer {
  margin: 10px 20px;
}
.customSearchContainer {
  margin: 10px 0px;
}
.selectCheckBox {
  margin: 10px 0px;
  display: flex;
  flex-direction: row;
}
.flex1 {
  flex: 1;
}
.removeIconContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.removeIconStyles {
  cursor: pointer;
}
.editConditionContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.descriptionHeader {
  font-weight: 800;
  font-size: 18px;
}
.iconContainer {
  padding: 10px 15px;
}
.selectedElement {
  border: 1px solid #b7b3a7;
  border-radius: 5px;
}
.selectedParagraph {
  padding: 0px 10px;
}
.orContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.defaultOr{
  background: #ebe0bf12 !important;
    box-shadow: none !important;
    color: black !important;
    border: 1px solid black !important;
}