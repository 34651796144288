.container {
  background-color: #fffaed;
}
.searchOptionContainer {
  background-color: #fffaed;
  z-index: 2313;
  width: 320px;
  max-height: 100px;
  box-shadow: 0 0 3px #ccc;
  overflow-y: scroll;
}
.searchOption:hover {
  background-color: #d3d3d3;
}
.searchOptionsPadding {
  padding: 10px 5px;
  cursor: pointer;
}
.minWidth {
  min-width: 400;
}
