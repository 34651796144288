.search{
    z-index: 100;
    height: 39px;
    width: 296px;
}
.main{
    position: fixed;
    right: 6px;
    top: 6px;
    z-index:100
}
